import ErrorPage from 'src/components/ErrorPage';

export default function NotFoundPage() {
  return (
    <ErrorPage
      code="404"
      message="The page you were looking for could not be found."
      linkMessage="Return to home page"
      linkUrl="/"
    />
  );
}
